import PropTypes from 'prop-types';
import styles from './maxContainer.module.scss';

const MaxContainer = ({ children, max, style }) => (
  <div
    style={{ ...style, maxWidth: max, flex: 1 }}
    className={styles.maxContainer}
  >
    {children}
  </div>
);

MaxContainer.propTypes = {
  children: PropTypes.node.isRequired,
  max: PropTypes.number,
  style: PropTypes.object
};

MaxContainer.defaultProps = {
  max: null,
  style: {}
};

export default MaxContainer;
